import React, { useEffect, useState } from 'react';
import { Button, Modal, ConfirmationDialog } from 'ui';
import classNames from 'classnames';

import FileSelector from './FileSelector';
import {
  CurrentConversationStore,
  setFileContexts,
  setIndexContexts,
} from 'stores/current-conversation';
import api from 'services/api';

import { getPersonalFiles, markFilesForDeletion } from 'stores/user';
import { logger } from 'services/logs/logger';
import { setGlobalError } from 'stores/global-message';
import {
  FileType,
  formatFileSize,
  IndexType,
  ResourceSelectionType,
} from 'utils/file-utils';

export const mapFileContextFiles = ({
  fileContextFiles,
  fileIdsUsedInConversation,
  tempFileContexts,
}) => {
  return fileContextFiles
    .filter((file) => file.processingStatus !== 'deleted')
    .map(
      (file: FileType) =>
        ({
          id: file.id,
          displayFileName: file.displayFileName,
          datetimeCreated: file.datetimeCreated,
          processingStatus: file.processingStatus,
          processingFailureComment: file.processingFailureComment,
          sublabel: file.sizeInBytes ? formatFileSize(file.sizeInBytes) : '',
          isSelected: tempFileContexts.includes(file.id),
          isUsedInConversation: fileIdsUsedInConversation.includes(file.id),
          itemType: 'file',
        }) as ResourceSelectionType,
    );
};

export const mapIndexContextIndexes = ({
  indexContextIndexes,
  indexIdsUsedInConversation,
  tempIndexContexts,
}) => {
  return indexContextIndexes.map(
    (index: IndexType) =>
      ({
        id: index.id,
        displayFileName: index.displayName,
        datetimeLastUpdated: index.datetimeLastUpdated,
        datetimeCreated: index.datetimeCreated,
        description: index.description,
        processingStatus: 'done',
        processingFailureComment: '',
        sublabel: index.description,
        numFiles: index.numFiles,
        numWebsites: index.numWebsites,
        isSelected: tempIndexContexts.includes(index.id),
        isUsedInConversation: indexIdsUsedInConversation.includes(index.id),
        itemType: 'index',
      }) as ResourceSelectionType,
  );
};

export const contextsHaveChanges = (contexts, tempContexts) => {
  if (Array.isArray(contexts) && Array.isArray(tempContexts)) {
    const areEqual =
      contexts.length === tempContexts.length &&
      contexts.every((file) => tempContexts.includes(file));

    return !areEqual; // Returns true if they are different
  }
  return false; // If not arrays, return false
};

const FileSelectorModal = ({ fileSelectorVisible, setFileSelectorVisible }) => {
  const {
    fileContexts,
    fileContextFiles,
    indexContextIndexes,
    indexContexts,
    fileIdsUsedInConversation,
    indexIdsUsedInConversation,
  } = CurrentConversationStore.useState((s) => ({
    fileContexts: s.fileContexts,
    fileContextFiles: s.fileContextFiles,
    indexContextIndexes: s.indexContextIndexes,
    indexContexts: s.indexContexts,
    fileIdsUsedInConversation: s.fileIdsUsedInConversation,
    indexIdsUsedInConversation: s.indexIdsUsedInConversation,
  }));
  const [tempFileContexts, setTempFileContexts] = useState<any[]>([]);
  const [tempIndexContexts, setTempIndexContexts] = useState<any[]>([]);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [resourceList, setResourceList] = useState<ResourceSelectionType[]>([]);

  useEffect(() => {
    const resources = [] as ResourceSelectionType[];
    if (fileContextFiles) {
      const items = mapFileContextFiles({
        fileContextFiles,
        fileIdsUsedInConversation,
        tempFileContexts,
      });

      resources.push(...items);
    }

    if (indexContextIndexes) {
      const items = mapIndexContextIndexes({
        indexContextIndexes,
        indexIdsUsedInConversation,
        tempIndexContexts,
      });
      resources.push(...items);
    }

    setResourceList(resources);
  }, [
    fileContextFiles,
    fileContexts,
    fileIdsUsedInConversation,
    indexContextIndexes,
    indexContexts,
    indexIdsUsedInConversation,
    tempFileContexts,
    tempIndexContexts,
  ]);

  useEffect(() => {
    if (!fileSelectorVisible) {
      setTempFileContexts(fileContexts);
      setTempIndexContexts(indexContexts);
    }
  }, [fileContexts, fileSelectorVisible, indexContexts]);

  useEffect(() => {
    if (!fileContextFiles || !Array.isArray(fileContextFiles)) {
      setIsValid(true);
      return;
    }
    const loadingOrErrorFilesSelected =
      fileContextFiles.filter(
        (m) =>
          m.processingStatus !== 'done' &&
          m.processingStatus !== 'deleted' &&
          tempFileContexts.includes(m.id),
      ) || [];

    setIsValid(loadingOrErrorFilesSelected.length === 0);
  }, [fileContextFiles, tempFileContexts]);

  useEffect(() => {
    if (fileSelectorVisible) {
      if (
        !Array.isArray(fileContexts) ||
        !Array.isArray(tempFileContexts) ||
        !Array.isArray(indexContexts) ||
        !Array.isArray(tempIndexContexts)
      ) {
        return setIsDirty(false);
      }

      setIsDirty(
        contextsHaveChanges(fileContexts, tempFileContexts) ||
          contextsHaveChanges(indexContexts, tempIndexContexts),
      );
    }
  }, [
    fileContexts,
    fileSelectorVisible,
    fileContextFiles,
    tempFileContexts,
    indexContexts,
    tempIndexContexts,
  ]);

  const deleteFilesAction = async (fileIds: string[]) => {
    markFilesForDeletion(fileIds);
    setConfirmDelete(false);

    const deletedFilePromises = [] as Promise<any>[];
    for (const fileId of fileIds) {
      deletedFilePromises.push(api.deleteFile(fileId));
    }
    const results = await Promise.all(deletedFilePromises);
    const hasError = results.filter((result) => {
      return !result.ok;
    });
    if (hasError.length > 0) {
      setGlobalError('There was an error deleting one or more files');
      logger.error('Error deleting files');
    }
    setTempFileContexts([]);
    getPersonalFiles();
  };

  return (
    <>
      <Modal
        title="Select contexts"
        className="select-file-modal"
        open={fileSelectorVisible}
        onOk={() => {
          setFileSelectorVisible(false);
        }}
        onCancel={() => {
          setTempFileContexts([...fileContexts]);
          setTempIndexContexts([...indexContexts]);
          setFileSelectorVisible(false);
        }}
        footer={[
          <Button
            className={classNames('full-size', {
              unavailable: true,
              active: isValid && isDirty,
            })}
            key="fileselector_apply"
            data-testid="fileselector_apply"
            onClick={() => {
              setFileContexts(tempFileContexts);
              setIndexContexts(tempIndexContexts);
              setFileSelectorVisible(false);
            }}
            disabled={!isValid || !isDirty}
          >
            Apply
          </Button>,
        ]}
      >
        <FileSelector
          selectedFileIds={tempFileContexts}
          selectedIndexIds={tempIndexContexts}
          resourceList={resourceList}
          currentConversationSelectedFileIds={[
            ...tempFileContexts,
            ...tempIndexContexts,
          ]}
          currentConversationUsedFileIds={[
            ...fileIdsUsedInConversation,
            ...indexIdsUsedInConversation,
          ]}
          setSelectedFiles={setTempFileContexts}
          setSelectedIndexes={setTempIndexContexts}
          setConfirmDelete={setConfirmDelete}
          setFileSelectorVisible={setFileSelectorVisible}
        />
        {!isValid && (
          <div
            className="warning"
            data-testid="file-selection-change-warning-message"
          >
            Invalid file selected. Changes cannot be applied.
          </div>
        )}
      </Modal>
      {confirmDelete && (
        <ConfirmationDialog
          text="Are you sure you want to delete these files?"
          open={confirmDelete}
          confirmText="Delete"
          cancelText="Cancel"
          reportResponse={(hasConfirmed) => {
            if (hasConfirmed) {
              deleteFilesAction(tempFileContexts);
            } else {
              setConfirmDelete(false);
            }
          }}
        />
      )}
    </>
  );
};

export default FileSelectorModal;
