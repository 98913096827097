import { useState, useEffect } from 'react';
import { CurrentConversationStore } from 'stores/current-conversation';
import { UserStore } from 'stores/user';

const MAX_PROMPT_THRESHOLD = 10;

type UserLimitNotification = {
  model: string;
  currentMonthlyUserPrompts: number;
  maxMonthlyPrompts: number;
  promptsLeft: number;
};

function useUserModelLimits() {
  const [userLimits, setUserLimits] = useState<UserLimitNotification | null>(
    null,
  );
  const maxMonthlyPrompts = UserStore.useState((s) => s.maxMonthlyPrompts);
  const currentMonthlyUserPromptsPerModel = UserStore.useState(
    (s) => s.currentMonthlyUserPromptsPerModel,
  );
  const currentModel = CurrentConversationStore.useState((s) => s.gptModel);

  useEffect(() => {
    if (
      !maxMonthlyPrompts ||
      !currentMonthlyUserPromptsPerModel ||
      !maxMonthlyPrompts[currentModel] ||
      !currentMonthlyUserPromptsPerModel[currentModel]
    ) {
      return; // exit if we don't have any limits set
    }

    const promptsLeft =
      maxMonthlyPrompts[currentModel] -
      currentMonthlyUserPromptsPerModel[currentModel];
    if (promptsLeft > MAX_PROMPT_THRESHOLD) {
      setUserLimits(null);
      return; // exit if we have more than the threshold left
    }

    const newNotification: UserLimitNotification = {
      model: currentModel,
      currentMonthlyUserPrompts:
        currentMonthlyUserPromptsPerModel[currentModel],
      maxMonthlyPrompts: maxMonthlyPrompts[currentModel],
      promptsLeft: promptsLeft < 0 ? 0 : promptsLeft,
    };

    setUserLimits(newNotification);
  }, [maxMonthlyPrompts, currentMonthlyUserPromptsPerModel, currentModel]);

  return userLimits;
}

export default useUserModelLimits;
