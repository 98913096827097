import { DateTime } from 'luxon';

import { Conversation } from 'ui/organisms/ConversationHistory';

export const filterDateByTodayOnly = (conversations: Conversation[]) => {
  const today = DateTime.now();

  return conversations.filter((c: Conversation) => {
    const conversationDate = DateTime.fromSQL(c.datetimeCreated);

    const isToday = conversationDate.hasSame(today, 'day');

    if (isToday) {
      return c;
    }

    return null;
  });
};

export const filterDateByYesterdayOnly = (conversations: Conversation[]) => {
  const today = DateTime.now();

  return conversations.filter((c: Conversation) => {
    const date = DateTime.fromSQL(c.datetimeCreated);

    const isYesterday = date.hasSame(today.minus({ days: 1 }), 'day');

    if (isYesterday) {
      return c;
    }

    return null;
  });
};

export const filterDateByPreviousWeek = (conversations: Conversation[]) => {
  const today = DateTime.now();
  const yesterday = today.minus({ days: 1 });

  return conversations.filter((c: Conversation) => {
    const date = DateTime.fromSQL(c.datetimeCreated);

    const startOfLastWeek = today.minus({ day: 7 });

    const isInLastWeekOnly =
      date >= startOfLastWeek &&
      date < today &&
      !date.hasSame(today, 'day') &&
      !date.hasSame(yesterday, 'day');

    if (isInLastWeekOnly) {
      return c;
    }

    return null;
  });
};

export const filterDateByPrevious30Days = (conversations: Conversation[]) => {
  const today = DateTime.now();
  const startOfLastWeek = today.minus({ day: 7 });
  const startOfLast30Days = today.minus({ day: 30 });

  return conversations.filter((c: Conversation) => {
    const date = DateTime.fromSQL(c.datetimeCreated);

    if (date >= startOfLast30Days && date < startOfLastWeek) {
      return c;
    }

    return null;
  });
};

export const filterDateByOlder = (conversations: Conversation[]) => {
  const today = DateTime.now();
  const startOfLast30Days = today.minus({ day: 30 });

  return conversations.filter((c: Conversation) => {
    const date = DateTime.fromSQL(c.datetimeCreated);

    if (date < startOfLast30Days) {
      return c;
    }

    return null;
  });
};

export const mapConversationHistoryToTimeBlocks = (conversations) => {
  if (!conversations.length) {
    return [];
  }

  return [
    {
      id: 1,
      title: 'Today',
      history: filterDateByTodayOnly(conversations),
    },
    {
      id: 2,
      title: 'Yesterday',
      history: filterDateByYesterdayOnly(conversations),
    },
    {
      id: 3,
      title: 'Previous 7 days',
      history: filterDateByPreviousWeek(conversations),
    },
    {
      id: 4,
      title: 'Previous 30 days',
      history: filterDateByPrevious30Days(conversations),
    },
    {
      id: 5,
      title: 'Older',
      history: filterDateByOlder(conversations),
    },
  ];
};

const historyMapper = { mapConversationHistoryToTimeBlocks };

export default historyMapper;
